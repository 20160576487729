import React from 'react';

// Card component
class Card extends React.Component {
    audioRef = React.createRef();

    handlePlaySound = () => {
        this.audioRef.current.play();
    };

    render() {
        return (
            <div className="card bg-base-300 w-auto shadow-xl text-left hover:shadow-2xl hover:shadow-amber-50" onClick={this.handlePlaySound}>
                <div className="card-body">
                    <h2 className="card-title text-primary">{this.props.title}</h2>
                    <p className="text-xs">{this.props.text}</p>
                </div>
                <figure className="mb-2 cursor-pointer ">
                    <img src={this.props.image} alt="555-Shoe" width="55px"/>
                </figure>
                <audio ref={this.audioRef} src={this.props.audio} preload="none"></audio>
            </div>
        );
    }
}

// App component
class App extends React.Component {
    render() {
        const cardData = [
            {
                title: "Agile!",
                text: "Because who doesn't love endless stand-up meetings and constant pivoting without actually getting anything done?",
                image: "/sb-im/video.png",
                audio: "/sb/Agile.mp3"
            },
            {
                title: "Microservices!",
                text: "Because managing a hundred tiny, interdependent services is obviously simpler than dealing with one big application",
                image: "/sb-im/video.png",
                audio: "/sb/mciro.mp3"
            },
            {
                title: "Tech!",
                text: "Because there's nothing like paying top dollar to be a beta tester for unfinished products!",
                image: "/sb-im/video.png",
                audio: "/sb/tech.mp3"
            },
            {
                title: "AI!",
                text: "Because who wouldn't want to turn their job over to an algorithm that sometimes thinks a cat is a toaster?",
                image: "/sb-im/video.png",
                audio: "/sb/ai.mp3"
            },
            {
                title: "ChatBOTS",
                text: "Because sometimes you just need a frustratingly inaccurate conversational partner to remind you that AI still has a long way to go.",
                image: "/sb-im/video.png",
                audio: "/sb/cb.mp3"
            },{
                title: "LLms",
                text: "Because using cutting-edge AI for fun and education is like riding a unicorn to work—it's exciting but not always practical",
                image: "/sb-im/video.png",
                audio: "/sb/llm.mp3"
            },
            {
                title: "Estimate",
                text: "Because who needs accuracy when you can just throw out a guess that sounds about right?",
                image: "/sb-im/video.png",
                audio: "/sb/ballpark.mp3"
            },
            {
                title: "Cloud",
                text: "Because who wouldn't want to trust their critical data to \"somewhere out there\" in cyberspace?",
                image: "/sb-im/video.png",
                audio: "/sb/Cloud.mp3"
            },
            {
                title: "DevOps",
                text: "Because why not combine the stress of development with the chaos of operations for double the fun?",
                image: "/sb-im/video.png",
                audio: "/sb/devops.mp3"
            },
            {
                title: "Ci/CD",
                text: "Because nothing says progress like automating the process of breaking your build every hour.",
                image: "/sb-im/video.png",
                audio: "/sb/ci.mp3"
            },
            {
                title: "k8s",
                text: "Because who wouldn't want to orchestrate a symphony of containers with the complexity of a Rube Goldberg machine?",
                image: "/sb-im/video.png",
                audio: "/sb/k8s.mp3"
            },
            {
                title: "jwt",
                text: "Because encoding your data into a long, unwieldy string is the new pinnacle of security practices.",
                image: "/sb-im/video.png",
                audio: "/sb/jwt.mp3"
            },
            {
                title: "RestAPI",
                text: "Because why settle for simplicity when you can overcomplicate data retrieval with unnecessary endpoints and status codes?.",
                image: "/sb-im/video.png",
                audio: "/sb/rest.mp3"
            }
            // You could add more cards' data here
        ];

        return (
            <div className="App m-auto p-6 grid grid-cols-2 sm:grid-cols-4 gap-2">
                {cardData.map((card, index) =>
                    <Card
                        key={index}
                        title={card.title}
                        text={card.text}
                        image={card.image}
                        audio={card.audio}
                    />
                )}
            </div>
        );
    }
}

export default App;